import axios from 'axios'

export const axiosInstance = axios.create({
  baseURL: 'https://mgw.mts.ru',
  headers: {
    'X-Device-Model': 'PC_FairPlay_v1',
    'X-Device-Id': 1,
    'X-App-Version': 1,
  },
})
