// TODO refactoring
var timerId: ReturnType<typeof setTimeout> | null = null;

export const debounce = (callback: () => any, timeout: number) => {
    return (...args: any) => {
        if (timerId) clearTimeout(timerId)
        timerId = setTimeout(() => {
            callback.apply(this, args)
        }, timeout)
    }
}

export function getNoun(number: number, one: string, two: string, five: string) {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return five;
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }
  return five;
}