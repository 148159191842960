import { makeAutoObservable } from 'mobx'
import { v4 } from 'uuid'
import { ShelfItemApi, ContentsApi, MetaResponseApi } from 'mgw-api'
import * as flashlightApi from 'flashlight-api'
import { gtm } from 'helpers/GTMService'
import { FavouritesStore } from './favourites.store'

export class CollectionStore {
  // @ts-ignore
  flashlightApi = new flashlightApi.ApiApi({
    basePath: 'https://services.kion.ru/explore',
    // basePath: '/mayak',
  })
  contentsApi = new ContentsApi(undefined, 'https://mgw.mts.ru')
  uuid: string = v4()
  
  // Итоговый массив подборок с фильмами
  _collections: MetaResponseApi[] = [] // рекомендации вместе с невалидными - {bu}
  negativeCollections: string[] = []
  showedCollections: string[] = []
  fetchStatus: 'pending' | 'completed' | 'none' = 'none'

  favouritesStore!: () => FavouritesStore;
  constructor(favouritesStore: () => FavouritesStore) {
    this.favouritesStore = favouritesStore
    makeAutoObservable(this)
  }

  // Рекомендации (только валидные)
  public get collectionsValid() {
    return this._collections.filter(x => !x.verticalImageUrl?.startsWith('{bu}'))
  }

  public get collectionsValidGid(): Set<string> {
    return this.collectionsValid.reduce<Set<string>>((acc, { gid }) => {
      if (!!gid) acc.add(gid)
      return acc
    }, new Set())
  }

  // Рекомендации (только невалидные bu)
  public get collectionsInvalid() {
    return this._collections.filter(x => x.verticalImageUrl?.startsWith('{bu}'))
  }

  public get collectionsInvalidGid(): Set<string> {
    return this.collectionsInvalid.reduce<Set<string>>((acc, { gid }) => {
      if (!!gid) acc.add(gid)
      return acc
    }, new Set())
  }

  public refreshCollections = () => {
    this._collections = []
  }

  // Не понравились (если пользователь нажал кнопку "ещё фильмы", то ранее показанные фильмы добавляются в negative)
  public addToNegative = () => {
    console.log('addToNegative', this.collectionsValidGid);
    const ids = Array.from(this.collectionsValidGid.values())
    this.negativeCollections = ids
  }

  // Показано (если пользователь нажал кнопку "добавить", то ранее показанные фильмы добавляются в showed)
  public addToShowed = () => {
    console.log('addToShowed', this.collectionsValidGid);
    const validIds = Array.from(this.collectionsValidGid.values())
    const invalidIds = Array.from(this.collectionsInvalidGid.values())
    this.showedCollections = validIds.concat(invalidIds)
  }

  // Понравившиеся (пользователь выбрал фильм руками)
  private addToPositive = (elements: (MetaResponseApi | null)[]) => {
    elements.forEach(value => {
      if (!!value?.gid && !this.collectionsValidGid.has(value.gid)) {
        this._collections.push(value)
      }
    })
  }

  public removeFromCollections = (gid: MetaResponseApi['gid']): void => {
    const index = this._collections.findIndex(el => el.gid === gid)
    this._collections.splice(index, 1)
  }

  recsLeft = true; // Ещё доступны рекомендации
  public getPageApiPagePut = async (pos_glos: ShelfItemApi['gid'][], count: number, seed: number) => {
    console.log('getPageApiPagePut', this.negativeCollections);
    
    try {
      const { data: { items, recs_left } } = await this.flashlightApi.getPageApiPagePut(
        count,
        this.uuid,
        {
          pos_glos,
          neg_glos: this.negativeCollections,
          showed_glos: this.showedCollections,
        },
        seed
      )

      const contents: MetaResponseApi[] = items.map((gid: ShelfItemApi['gid']) =>
        this.contentsApi
          .getMetaByGid(gid, '0', 'PC_FairPlay_v1')
          .then(({ data }) => {
            if (data.verticalImageUrl?.startsWith('{bu}')) {
              data.verticalImageUrl = data.verticalImageUrl.replace('{bu}', 'https://htv-vsc.kion.ru:32133/CPS/images/universal/film')
            }
            return data
          })
          .catch(() => null)
      )
      this.recsLeft = recs_left;
      
      return await Promise.all(contents)
    } catch (e) {
      console.warn(e)
      return []
    }
  }

  public fetchPositiveFavourites = async (
    pos_glos: ShelfItemApi['gid'][], count: number, seed: number, 
    addToNegative: boolean, addToShowed: boolean
  ) => {
    console.log('fetchPositiveFavourites', addToNegative, addToShowed);
    
    if (addToNegative) this.addToNegative()

    if (addToShowed) this.addToShowed()
    else {
      const invalidIds = Array.from(this.collectionsInvalidGid.values())
      this.showedCollections = invalidIds
    }

    this.fetchStatus = !this._collections.length ? 'pending' : 'completed';
    const response = await this.getPageApiPagePut(pos_glos, count, seed)
    this.fetchStatus = 'completed'

    this.addToPositive(response)

    // Эвент: показ страницы c результатом
    gtm.pushEvent({
      event: 'mayak_show', 
      event_name: 'mayak_show', 
      search_films: this.favouritesStore().favourites.map(x => x.gid.toString()),
      recommended_films: this._collections.map(x => x.gid!.toString()),
    })
  }
}
